<template lang="pug">
.container-fluid(style="min-height: 50vh;")
    .row.p-5
        .berend
    .row.px-5.pb-5
        p
         | Operandum is opgericht door Berend de Vries.
         | Praktisch zijn hele werkzame leven is hij actief in en rond de watersport.
         | Begonnen bij een jachtmakelaardij, lange tijd in het verzekeringsbedrijf
         | voor de watersportbranche en inmiddels al weer bijna 10 jaar ondernemer in werving en selectie.
         | Met natuurlijk de focus op jachtbouw, watersport en jachtservice.
         | Daarin heeft Berend gedurende zijn loopbaan dan ook een uitgebreid netwerk opgebouwd.
         | Niet in de laatste plaats is hij zelf natuurlijk ook geregeld te vinden op het water.
         | Niet onbelangrijk zo vindt hij als je de ‘taal’ wil spreken en de wensen van je klanten wil begrijpen.
         | Favoriete vaargebied? Eigenlijk overal wel,
         | maar Zeeland als uitvalsbasis voor zeiltochten naar Engeland en Frankrijk is toch wel licht favoriet.
</template>

<script>
export default {
    name : 'About'
}
</script>

<style lang="scss">
.berend {
    background-image: url(../assets/berend.jpg);
    background-size: cover;
    height: 140px;
    width: 140px;
    border-radius: 50%;
}
</style>
